//INFO: 화면설명
<script>
import Uid from '@/util/uid'
export default {
  components: {},
  props: ['name', 'value', 'label', 'type', 'checked', 'disabled'],
  emits: ['onChecked'],
  data() {
    return {
      id: '',
      inputChecked: false,
      inputDisabled: false
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.id = Uid()
    this.inputChecked = this.checked
    this.inputDisabled = this.disabled
  },
  unmounted() {},
  methods: {
    changeHandler(e) {
      this.$emit('onChecked', e)
    }
  },
  watch: {
    checked() {
      this.inputChecked = this.checked
    },
    disabled() {
      this.inputDisabled = this.disabled
    }
  }
}
</script>

<template>
  <div class="round">
    <input
      :type="`${type || 'radio'}`"
      :name="name"
      :value="value"
      :id="id"
      @change="changeHandler"
      :checked="inputChecked"
      :disabled="inputDisabled"
    />
    <label class="round-checkbox" :for="id"></label>
    <label class="cursor-pointer checkbox-label" :for="id">
      <span>{{ label }}</span>
    </label>
  </div>
</template>

<style scoped>
.round {
  position: relative;
  padding: 0px 10px 0px 10px;
}
.round .round-checkbox {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 0px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.round input.invalid ~ .round-checkbox {
  border-color: var(--wf-error-color);
}
.round .round-checkbox:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  width: 12px;
  left: 4px;
  top: 5px;
  position: absolute;
  opacity: 0;
  transform: rotate(-45deg);
}
.round input[type='radio'],
.round input[type='checkbox'] {
  visibility: hidden;
}
.round input[type='radio']:checked + .round-checkbox,
.round input[type='checkbox']:checked + .round-checkbox {
  background-color: var(--wf-primary-color);
  border-color: var(--wf-misty-color);
}
.round input[type='radio']:checked:disabled + .round-checkbox,
.round input[type='checkbox']:checked:disabled + .round-checkbox {
  background-color: var(--wf-gray-color);
}
.round input[type='radio']:disabled ~ .checkbox-label,
.round input[type='checkbox']:disabled ~ .checkbox-label {
  color: var(--wf-gray-color);
}
.round input[type='radio']:checked + .round-checkbox:after,
.round input[type='checkbox']:checked + .round-checkbox:after {
  opacity: 1;
}

.checkbox-label {
  margin-left: 0.25em;
}
</style>
