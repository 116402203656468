<script setup>
import TermsVue from '@/components/Member/Register/Terms.vue'
import FormVue from '@/components/Member/Register/Form.vue'
import { ref } from 'vue'

import LanguageConverter from '@/util/LanguageConverter'

const step = ref(0)

const formSubmitHandler = (e) => {
  step.value = 1
}
</script>

<template>
  <section class="wf__register">
    <section class="wf__register-title-wrapper">
      <h4 class="wf__register-title">{{ LanguageConverter({ en: 'Sign Up', kr: '회원가입' }) }}</h4>
      <h5 class="wf__register-subtitle">
        {{ LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }) }}
      </h5>
      <h6 class="wf__register-description">
        {{
          LanguageConverter({
            en: 'Welcome to PromisingLabs. Please use the service after signing up.',
            kr: '유망연구실에 오신 것을 환영합니다. 회원가입 후 서비스를 사용해주세요.'
          })
        }}
      </h6>
    </section>

    <TermsVue v-if="step === 0" @submitEvent="formSubmitHandler"></TermsVue>
    <FormVue v-if="step === 1"></FormVue>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wf__register {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__register-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wf__register-title {
  font-size: 36px;
  font-weight: bold;
}
.wf__register-subtitle {
  font-size: 24px;
  color: var(--wf-secondary-color);
}
.wf__register-description {
  font-size: 16px;
  color: var(--wf-gray-color);
}
</style>
