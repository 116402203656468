<script setup>
import VaildationInputVue from '@/components/ui/VaildationInput.vue'
import CustomRadioVue from '@/components/ui/CustomRadio.vue'
import { signUp } from '@/api/auth'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  validateRegistId, //
  validateRegistPassword,
  validateEngKor,
  validateRegistLabName,
  validateEmail
} from '@/util/reg-validators'
import LanguageConverter from '@/util/LanguageConverter'

const router = useRouter()

const validationInputs = [
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'Please Enter ID', kr: '아이디를 입력하세요' }),
    name: 'userId',
    label: LanguageConverter({ en: 'ID', kr: '아이디' }),
    validationRules: [
      {
        rule: ({ value }) => validateRegistId(value),
        invalidText: '아이디는 4~10 자리의 영문자, 숫자만 입력가능합니다.'
      }
    ],
    required: true
  },
  {
    type: 'password',
    placeholder: LanguageConverter({ en: 'Please Enter Password', kr: '비밀번호를 입력하세요' }),
    name: 'password',
    label: LanguageConverter({ en: 'Password', kr: '비밀번호' }),
    validationRules: [
      {
        rule: ({ value }) => validateRegistPassword(value),
        invalidText: '비밀번호는 8~16 자리의 문자, 숫자, !, @, #, $, %, ^, &, *를 포함해야합니다.'
      }
    ],
    required: true
  },
  {
    type: 'password',
    placeholder: LanguageConverter({ en: 'Please Enter Password Again', kr: '비밀번호 확인' }),
    label: LanguageConverter({ en: 'Password Check', kr: '비밀번호 확인' }),
    validationRules: [{ rule: ({ value }, passwordValue) => value && value === passwordValue, invalidText: '비밀번호가 같지않습니다.' }],
    required: true
  },
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'Please Enter Name', kr: '이름을 입력하세요' }),
    name: 'name',
    label: LanguageConverter({ en: 'Name', kr: '이름' }),
    validationRules: [{ rule: ({ value }) => validateEngKor(value), invalidText: '이름은 한글과 영어만 입력가능합니다.' }],
    required: true
  },
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'Please Enter Lab Name', kr: ' 연구실 이름을 입력하세요' }),
    name: 'labName',
    label: LanguageConverter({ en: 'LabName', kr: '연구실이름' }),
    validationRules: [
      { rule: ({ value }) => !value || validateRegistLabName(value), invalidText: '연구실 이름은 한글, 영어, 숫자만 입력가능합니다.' }
    ]
  },
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'Please Enter University', kr: '소속 대학교를 입력하세요' }),
    name: 'university',
    label: LanguageConverter({ en: 'University', kr: '소속대학교' }),
    validationRules: [{ rule: ({ value }) => !value || validateEngKor(value), invalidText: '소속 대학교는 한글, 영어만 입력가능합니다.' }]
  },
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'Please Enter Email', kr: '이메일을 입력하세요' }),
    name: 'email',
    label: LanguageConverter({ en: 'Email', kr: '이메일' }),
    validationRules: [{ rule: ({ value }) => validateEmail(value), invalidText: '이메일형식으로 입력해주십시오' }],
    required: true
  }
]

const vaildationInputRef = ref([])
const form = ref(null)
const isValid = ref(false)
const setVaildationInputRef = ({ input, label }) => {
  vaildationInputRef.value.push({ input, label })
}

const inputValidator = () => {
  const inputs = vaildationInputRef.value.map((item, i) => {
    return { ...validationInputs[i], ...item }
  })

  const passwordValue = inputs[1].input.value

  for (const item of inputs) {
    item.input.classList.remove('invalid')

    for (const { rule, invalidText } of item.validationRules) {
      if (!rule(item.input, passwordValue)) {
        item.label.textContent = invalidText
        item.input.classList.add('invalid')
        return
      }
    }

    item.input.classList.add('valid')
  }

  isValid.value = true
}

const formSubmitHandler = async (e) => {
  if (!isValid.value) return inputValidator()

  const { userId, password, name, labName, university, email, advertisementAgreement } = form.value

  const info = {
    userId: userId.value,
    password: password.value,
    name: name.value,
    email: email.value,
    labName: labName.value,
    university: university.value,
    advertisementAgreement: advertisementAgreement.value,
    termsAgreement: 1,
    privacyAgreement: 1
  }

  const result = await signUp(info)

  if (result.code === 10003) {
    form.value.userId.focus()
    return alert('이미 존재하는 아이디입니다.')
  }
  if (result.code === 'ER_DUP_ENTRY') {
    form.value.email.focus()
    return alert('이미 존재하는 이메일입니다.')
  }

  router.replace('/member/login')
  return alert('회원가입되었습니다.')
}

onMounted(() => {
  document.body.scrollTop = 0
  form.value.reset()
})
</script>

<template>
  <form class="wf__register-form" ref="form" @submit.prevent="formSubmitHandler">
    <section class="wf__register-form-body">
      <div class="wf__register-form-input-wrapper" v-for="(input, i) in validationInputs" :key="i">
        <label for="" :class="{ required: input?.required }">{{ input.label }}</label>
        <VaildationInputVue class="wf__register-form-input" @blurEvent="inputValidator" @setRef="setVaildationInputRef" :item="input" />
      </div>

      <div class="wf__register-form-checkbox-wrapper">
        <label for="">{{ LanguageConverter({ en: 'Promotion Agreement', kr: '이메일 수신동의' }) }}</label>

        <div>
          <CustomRadioVue
            :label="LanguageConverter({ en: 'Yes', kr: '예' })"
            name="advertisementAgreement"
            value="1"
            :checked="true"
          ></CustomRadioVue>
          <CustomRadioVue :label="LanguageConverter({ en: 'No', kr: '아니오' })" name="advertisementAgreement" value="1"></CustomRadioVue>
        </div>
      </div>
    </section>

    <button class="wf__register-form-submit" type="submit">회원가입</button>
  </form>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  width: 100%;
  max-width: 500px;
}
.wf__register-form-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}
.wf__register-form-input-wrapper,
.wf__register-form-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wf__register-form-checkbox-wrapper {
  justify-content: flex-start;
  gap: 1rem;
}
.wf__register-form-checkbox-wrapper > label,
.wf__register-form-input-wrapper > label {
  color: var(--wf-primary-color);
}

.wf__register-form-checkbox-wrapper > label.required:after,
.wf__register-form-input-wrapper > label.required:after {
  content: ' *';
  color: var(--wf-primary-color);
}

.wf__register-form-input {
  width: 80%;
}

.wf__register-form-submit {
  margin-top: 2rem;
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--wf-primary-color);
  border-radius: 0.25em;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding: 0.35em 1em;
}
.wf__register-form-submit:hover {
  opacity: 0.8;
}

.round {
  position: relative;
  float: left;
  padding: 0px 10px 0px 10px;
}
.round .round-checkbox {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  right: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.round input.invalid ~ .round-checkbox {
  border-color: var(--wf-error-color);
}
.round .round-checkbox:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  width: 12px;
  left: 4px;
  top: 5px;
  position: absolute;
  opacity: 0;
  transform: rotate(-45deg);
}
.round input[type='radio'] {
  visibility: hidden;
}
.round input[type='radio']:checked + .round-checkbox {
  background-color: #aaa;
  border-color: #eee;
}
.round input[type='radio']:checked + .round-checkbox:after {
  opacity: 1;
}
</style>
