<script setup>
import { getContentById } from '@/api/admin/contents'
import TextViewerVue from '@/components/TextViewer.vue'
import CustomRadioVue from '@/components/ui/CustomRadio.vue'
import { defineEmits, onMounted, ref } from 'vue'
import LanguageConverter from '@/util/LanguageConverter'

const form = ref(null)
const disagreedMsg = ref(null)
const serviceBoard = ref(null)
const serviceBoardData = ref({
  boardName: 'content',
  boardId: 1
})

const privacyBoard = ref(null)
const privacyBoardData = ref({
  boardName: 'content',
  boardId: 3
})

const emits = defineEmits(['submitEvent'])

const notCheckedHandler = (checkbox) => {
  disagreedMsg.value.classList.add('show')
  checkbox.classList.add('invalid')
}

const formSubmitHandler = (e) => {
  const checkboxList = e.target.agree
  for (const checkbox of checkboxList) {
    if (!checkbox.checked) return notCheckedHandler(checkbox)
  }
  emits('submitEvent', e)
}

const agreeAll = (e) => {
  const checkboxList = form.value.agree
  const isAgree = e.target.checked
  for (const checkbox of checkboxList) {
    checkbox.checked = isAgree
  }
}

onMounted(async () => {
  serviceBoard.value = (await getContentById(1)).content ?? ''
  privacyBoard.value = (await getContentById(3)).content ?? ''

  const formEl = form.value
  // 화면 진입 시 form 초기화
  formEl.reset()

  const checkboxList = formEl.agree
  for (const el of checkboxList) {
    el.classList.remove('invalid')
  }
})
</script>

<template>
  <form class="wf__register-terms" ref="form" @submit.prevent="formSubmitHandler">
    <section class="wf__register-terms-content">
      <h4 class="wf__register-terms-title">회원가입 이용약관</h4>
      <div class="divider"></div>

      <div class="wf__register-terms-content-view">
        <TextViewerVue :boardData="serviceBoardData" :propsData="serviceBoard"></TextViewerVue>
      </div>

      <div class="divider"></div>

      <div class="wf__register-terms-checkbox">
        <CustomRadioVue type="checkbox" name="agree" value="1" label="동의" />
      </div>
    </section>

    <section class="wf__register-terms-content">
      <h4 class="wf__register-terms-title">개인정보 수집동의</h4>
      <p class="wf__register-terms-lab-info">
        유망연구실은 「개인정보보호법」 제15조, 제17조, 제22조, 제23조, 제24조에 따라 아래와 같이 개인정보의 수집. 이용에 관하여 귀하의
        동의를 얻고자 합니다. 유망연구실은 이용자의 사전 동의 없이는 이용자의 개인정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이
        이용하고 있습니다. 이용자가 제공한 모든 정보는 아래의 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 이를
        알리고 동의를 구할 것입니다.
      </p>

      <div class="divider"></div>

      <div class="wf__register-terms-content-view">
        <TextViewerVue :boardData="privacyBoardData" :propsData="privacyBoard"></TextViewerVue>
      </div>

      <div class="divider"></div>

      <div class="wf__register-terms-checkbox">
        <CustomRadioVue type="checkbox" name="agree" value="1" label="동의" />
      </div>
    </section>

    <section>
      <div class="wf__disagreed" ref="disagreedMsg">약관에 동의하셔야 가입이 가능합니다.</div>

      <div class="text-left">
        <CustomRadioVue type="checkbox" value="1" label="전체동의" @onChecked="agreeAll" />
      </div>
      <button class="next-btn e-btn wf__btn btn-primary" type="submit">
        {{ LanguageConverter({ en: 'Next', kr: '다음' }) }}
      </button>
    </section>
  </form>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.next-btn {
  font-size: var(--wf-text-16);
  padding-left: 1.25em !important;
  padding-right: 1.25em !important;
  float: right;
}

.divider {
  height: 1px;
  background-color: var(--wf-gray-color);
  margin: 1em 0;
}

.wf__register-terms {
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
}

.wf__register-terms-title {
  text-align: left;
  font-weight: bold;
  font-size: var(--wf-text-24);
}
.wf__register-terms-content {
  margin-top: 2em;
  text-align: left;
}
.wf__register-terms-content-view {
  width: 100%;
  height: 15em;
  overflow: auto;
  padding: 0 2em;
}
.wf__register-terms-content-view::-webkit-scrollbar {
  width: 10px;
}
.wf__register-terms-content-view::-webkit-scrollbar-thumb {
  background-color: var(--wf-gray-color);
  border-radius: 10px;
}

.wf__register-terms-lab-info {
  background-color: var(--wf-gray-600);
  padding: 20px;
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
}

.wf__disagreed {
  display: none;
  text-align: left;
  color: var(--wf-error-color);
}
.wf__disagreed.show {
  display: block;
}

@media screen and (max-width: 992px) {
  .wf__register-terms {
    padding: 1em;
  }
}
</style>
