<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'

const input = ref(null)
const label = ref(null)
defineProps({
  item: Object
})

const emits = defineEmits(['setRef', 'blurEvent'])
const blurHandler = (e) => {
  emits('blurEvent', e)
}

onMounted(() => {
  emits('setRef', { input: input.value, label: label.value })
})
</script>

<template>
  <div class="wf__vaildation-wrapper">
    <input
      class="wf__vaildation-input"
      ref="input"
      :name="item.name"
      :type="item.type"
      :placeholder="item.placeholder"
      :autocomplete="item.type === 'password' ? 'new-password' : 'off'"
      @blur="blurHandler"
    />
    <span class="wf__vaildation-valid-text"></span>
    <span class="wf__vaildation-invalid-text" ref="label"></span>
  </div>
</template>

<style scoped>
.wf__vaildation-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.wf__vaildation-input {
  outline: none;
  border: 1px solid var(--wf-gray-color);
  border-radius: 0.5rem;
  padding: 0.5em 1em;
}
.wf__vaildation-input.required {
}
.wf__vaildation-input ~ .wf__vaildation-invalid-text {
  display: none;
}
.wf__vaildation-input ~ .wf__vaildation-valid-text {
  display: none;
}

.wf__vaildation-input.invalid {
  border-color: var(--wf-error-color) !important;
}
.wf__vaildation-input.valid {
  border-color: var(--wf-primary-color);
}
.wf__vaildation-input.invalid ~ .wf__vaildation-invalid-text {
  display: block;
  color: var(--wf-error-color);
}
.wf__vaildation-input.valid ~ .wf__vaildation-valid-text {
  display: block;
  color: var(--wf-primary-color);
}
</style>
